import React from 'react';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import './App.scss';
import {RequireAuth} from './components/RequireAuth';
import {Login} from './pages/Login';
import AuthProvider from './core/contexts/Auth.context';
import {Home} from './pages/Home';
import {Profile} from './pages/Profile';
import {Race} from './pages/Race';
import {Register} from './pages/Register';

function App() {

    return (
        <div className="App">
            <BrowserRouter>
                <AuthProvider>
                    <Routes>
                        <Route path="/"  element={
                            <RequireAuth>
                                <Home />
                            </RequireAuth>
                        }/>
                        <Route path="/profile" element={
                            <RequireAuth>
                                <Profile />
                            </RequireAuth>
                        }/>
                        <Route path="/race/:id" element={
                            <RequireAuth>
                                <Race />
                            </RequireAuth>
                        }/>
                        <Route path="/login" element={<Login />}/>
                        <Route path="/register" element={<Register />}/>
                    </Routes>
                </AuthProvider>
            </BrowserRouter>
        </div>
    );
}

export default App;
