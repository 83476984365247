import {HomeIcon, UserIcon} from '@heroicons/react/outline'
import {Link, useMatch} from 'react-router-dom';
import {ReactNode} from 'react';


export const BottomBar = ({ children, showChildren }: { children?: ReactNode, showChildren?: boolean}) => {
    const homeMatch = useMatch('/')
    const profileMatch = useMatch('/profile')

    return (
        <div className="z-50 bg-white sticky bottom-0 h-16 w-screen rounded-t-3xl shadow-bottom-bar overflow-hidden relative">
            <div
                id='bottom-bar-replace'
                className={`w-full h-full px-8 absolute left-0 top-0 ${ showChildren ? 'visible opacity-100' : 'invisible opacity-0'}`}
            >
                { children }
            </div>
            <div
                id='bottom-bar-main'
                className={`w-full h-full absolute left-0 right-0 flex justify-around items-center ${ showChildren ? 'invisible opacity-0' : 'visible opacity-100'}`}
            >
                <Link to="/" className="flex flex-col items-center space-y-1">
                    <HomeIcon className={`w-7 h-7 ${homeMatch && 'text-blue'}`} />
                    {homeMatch && <div className="w-1 h-1 rounded-full bg-blue" />}
                </Link>

                <Link to="/profile" className="flex flex-col items-center space-y-1">
                    <UserIcon className={`w-7 h-7 ${profileMatch && 'text-blue'}`} />
                    {profileMatch && <div className="w-1 h-1 rounded-full bg-blue" />}
                </Link>
            </div>
        </div>
    )
}