import React, {HTMLInputTypeAttribute, InputHTMLAttributes, useState} from 'react';
import {UseFormRegisterReturn} from 'react-hook-form';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
    type?: HTMLInputTypeAttribute
    inputClassName?: string | object
    register?: UseFormRegisterReturn
    error?: string
    onChange?: (e: any) => void
}

export const TextInput = ({type, inputClassName, register, error, onChange, ...props }: InputProps) => {
    const goChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (onChange) {
            onChange(e)
        }
    }

    return (
        <div>
            <input
                className={`
                    ${inputClassName ?? ''}
                    py-2.5 px-3 bg-well rounded-xl border border-transparent focus:border-blue outline-none transition-colors ease-in-out
                    ${!error && 'mb-6'}
                    ${error && 'border-red-500'}`
                }
                {...register}
                {...props}
                onChange={goChange}
                type={type ?? 'text'}
            />
            {error && <small className="text-red-500 h-6 text-xs">{error}</small>}
        </div>
    )
}