import {data} from 'autoprefixer';

export const get = <T>(url: string): Promise<T> => {
    return new Promise<T>((resolve, reject) => {
        fetch(`${process.env.REACT_APP_API_URL}/api${url}`, {
            headers: getHeaders(),
        }).then(res => {
            if (!res.ok) {
                throw res
            }

            return res.json()
        }).then(
            data => {
                resolve(data as T)
            },
            error => {
                throw error
            }
        ).catch(error => {
            if (error instanceof Response) {
                if (error.status === 401) {
                    window.location.href = '/login';
                    localStorage.removeItem('token')
                }
            }

            reject(error)
        })
    })
}

export const post = <T>(url: string, body: any) : Promise<T> => {
    return new Promise<T>((resolve, reject) => {
        fetch(`${process.env.REACT_APP_API_URL}/api${url}`, {
            method: 'POST',
            headers: getHeaders(body),
            body: body instanceof FormData ? body : JSON.stringify(body)
        }).then(res => {
            if (!res.ok) {
                throw res
            }

            return res.json()
        }).then(
            data => {
                resolve(data)
            },
            error => {
                throw error
            }
        ).catch(error => {
            if (error instanceof Response) {
                if (error.status === 401 && window.location.pathname !== '/login') {
                    window.location.href = '/login';
                    localStorage.removeItem('token')
                }
            }

            reject(error)
        })
    })
}

function getHeaders(data?: any): HeadersInit {
    const headers: {'Content-Type': string | null, Authorization: string | null} = {
        'Content-Type': null,
        'Authorization': null,
    };

    if (data == null) {
        headers['Content-Type'] = 'application/json'
    } else {
        if (!(data instanceof FormData)) {
            headers['Content-Type'] = 'application/json'
        }
    }

    if (localStorage.getItem('token')) {
        headers.Authorization = `Bearer ${localStorage.getItem('token')}`
    }

    return Object.fromEntries(Object.entries(headers).filter(([_, v]) => v != null)) as HeadersInit
}