import {BottomBar} from '../components/BottomBar';
import {Header} from '../components/Header';
import {Page} from '../components/Page';
import {ChevronRightIcon} from '@heroicons/react/outline';
import {useEffect, useState} from 'react';
import {Spinner} from '../components/Spinner';
import {get} from '../core/Api';
import {ListOf, RaceSummary} from '../core/interfaces/Entities';
import Moment from 'react-moment';
import {Link} from 'react-router-dom';

export const Home = () => {
    const [loadingRaces, setLoadingRaces] = useState(false);
    const [racesError, setRacesError] = useState(false);
    const [races, setRaces] = useState<RaceSummary[]>([]);

    useEffect(() => {
        setLoadingRaces(true)
        get<ListOf<RaceSummary>>('/race').then(res => {
            setLoadingRaces(false);
            setRaces(res.items)
        }).catch(err => {
            setRacesError(err)
        })
    }, [])

    return (
        <>
            <Page className="p-6">
                <Header>
                    <h1 className="font-bold">Home</h1>
                </Header>
                <div>
                    <h5 className="font-bold">Volgende koersen</h5>
                    <div className="mt-3 space-y-2">
                        { loadingRaces &&
                            <div className="flex justify-center">
                                <Spinner className="!fill-black" />
                            </div>
                        }
                        { racesError && <small className="text-red-500">Er is iets fout gegaan tijdens het ophalen van de koersen</small>}
                        { !loadingRaces && races.length <= 0 && <small className="text-gray-500">Geen volgende koersen gepland</small>}
                        { races.map( (race, i) =>
                            <Link key={i} to={`/race/${race.id}`} className="bg-white rounded-xl p-3 flex space-x-3 items-center select-none">
                                <div className="flex flex-col items-center px-2 min-h-[2rem]">
                                    { race.date
                                        ? <Moment className="font-bold h-5 w-5" format={'D'}>{race.date}</Moment>
                                        : <div className='w-5 h-4 text-center leading-[0.75]'>-</div>
                                    }
                                    { race.date
                                        ? <Moment className="text-[0.7rem] w-5" format={'MMM'}>{race.date}</Moment>
                                        : <div className='w-5 h-4 text-center  leading-[0.75]'>-</div>
                                    }
                                </div>
                                <div className="w-[1px] self-stretch bg-black/50" />
                                <div className="flex-1 space-y-1">
                                    <p className="font-bold min-h-[1.25rem] leading-4">{race.title}</p>
                                    <p className="text-[0.7rem] italic text-gray-500">{race.place}</p>
                                </div>
                                <ChevronRightIcon className="h-4 w-4" />
                            </Link>
                        )}
                    </div>
                </div>
            </Page>
            <BottomBar />
        </>
    )
}