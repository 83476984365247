import {Navigate, useNavigate} from 'react-router-dom';
import {ReactElement, useEffect, useState} from 'react';
import {useAuth} from '../core/contexts/Auth.context';
import {Spinner} from './Spinner';
import {get} from '../core/Api';

export const RequireAuth = ({children}: {children: ReactElement}) => {
    const auth = useAuth()
    const [loading, setLoading] = useState(auth.token == null)

    useEffect(() => {
        if (localStorage.getItem('token')) {
            auth.setToken(localStorage.getItem('token'))
            get('/validate-token')
                .then(() => {
                    setLoading(false)
                }).catch(() => {
                    auth.setToken(null)
                    setLoading(false);
                })
        } else {
            setLoading(false)
        }
    })

    return loading ? <div className='w-screen h-screen flex justify-center items-center'>
        <Spinner className='w-10 h-10 fill-black' />
    </div> : auth.token ? children : <Navigate to="/login" />
}