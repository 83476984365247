import {Button} from '../components/Button';
import {TextInput} from '../components/Form/TextInput';
import {FormLabel} from '../components/Form/FormLabel';
import {useAuth} from '../core/contexts/Auth.context';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from "yup";
import React, {useEffect, useRef, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {post} from '../core/Api';

const loginSchema = yup.object({
    firstName: yup.string().required('Vul je voornaam in'),
    lastName: yup.string().required('Vul je achternaam in'),
    email: yup.string().email('Vul een email geldig adres in').required('Vul een email adres in'),
    password: yup.string().required('Vul een wachtwoord in'),
    passwordCheck: yup.string().required('Herhaal je wachtwoord'),
    registerToken: yup.string().nullable()
})

export const Register = () => {
    const navigate = useNavigate();
    const auth = useAuth();
    const submitButton = useRef(null);
    const [error, setError] = useState<string | null>(null)
    const { register, handleSubmit, formState: { errors }} = useForm({
        resolver: yupResolver(loginSchema),
        mode: 'onChange'
    })

    const doLogin = (values: any) => {
        setError(null)
        post('/register', values)
            .then((data) => {
                console.log(data);
            })
            .catch((err) => {
                setError(err)
            })
    }

    const onEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            (e.target as HTMLInputElement).blur();
            (submitButton.current as unknown as HTMLButtonElement)?.click()
        }
    }

    return (
        <div className="mx-auto container h-screen p-6 flex justify-center items-center">
            <form onSubmit={handleSubmit(doLogin)} className="w-full space-y-2" noValidate>
                <div>
                    <FormLabel>Voornaam</FormLabel>
                    <TextInput type="text" register={register('firstName')} inputClassName="w-full" error={errors.firstName?.message} />
                </div>

                <div>
                    <FormLabel>Achternaam</FormLabel>
                    <TextInput type="text" register={register('lastName')} inputClassName="w-full" error={errors.lastName?.message} />
                </div>

                <div>
                    <FormLabel>Email</FormLabel>
                    <TextInput type="email" register={register('email')} inputClassName="w-full" error={errors.email?.message} />
                </div>

                <div>
                    <FormLabel>Wachtwoord</FormLabel>
                    <TextInput type="password" register={register('password')} inputClassName="w-full" error={errors.password?.message} onKeyDown={onEnter} />
                </div>

                <div>
                    <FormLabel>Wachtwoord check</FormLabel>
                    <TextInput type="password" register={register('passwordCheck')} inputClassName="w-full" error={errors.passwordCheck?.message} onKeyDown={onEnter} />
                </div>

                <input className='hidden' {...register('registerToken')} />

                { error && <small className='text-red-500'>{ error }</small>}
                <div>
                    <Button ref={submitButton} type="submit" className="w-full mt-5" loading={auth.pending}>Register</Button>
                </div>
            </form>
        </div>
    )
}