import React, {MutableRefObject, ReactNode} from 'react';
import {Spinner} from './Spinner';

interface ButtonProps {
    type?: 'button' | 'reset' | 'submit'
    className?: string
    children: ReactNode | ReactNode[]
    loading?: boolean,
    onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void
    form?: string
}

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(({type, className, children, loading, onClick, form}: ButtonProps, ref) => {
    const doClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        if (!loading && onClick) {
            onClick(e)
        }
    }

    return (
        <button
            ref={ref}
            type={type ?? 'button'}
            form={form}
            className={`${className} text-sm uppercase bg-blue text-white py-2.5 px-8 rounded-lg relative overflow-hidden`}
            onClick={doClick}
        >
            <div className={`${loading && 'opacity-0'}`}>{children}</div>
            {loading && <div className="absolute w-full h-full top-0 left-0 flex items-center justify-center">
                <Spinner />
            </div>}
        </button>
    )
});