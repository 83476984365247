import {Page} from '../components/Page';
import {Header} from '../components/Header';
import {BottomBar} from '../components/BottomBar';
import {useEffect, useState} from 'react';
import {Tabs, Tab} from '@mui/material';
import {useNavigate, useParams} from 'react-router-dom';
import {get} from '../core/Api';
import {PlanningRow, Race as RaceObject, RouteRow, SupplyLocation, User} from '../core/interfaces/Entities';
import {ArrowLeftIcon, CalendarIcon} from '@heroicons/react/outline';
import Moment from 'react-moment';
import {Card} from '../components/Card';
import {ProfileImage} from '../components/ProfileImage';
import {LocationMarkerIcon} from '@heroicons/react/solid';
import {Spinner} from '../components/Spinner';

export const Race = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [tab, setTab] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(true);
    const [fetchError, setFetchError] = useState<string | null>(null)
    const [raceData, setRaceData] = useState<RaceObject | null>(null)
    const [hightlightSupplyLocation, setHightlightSupplyLocation] = useState<string | null>(null);

    useEffect(() => {
        get<RaceObject>(`/race/${id}`)
            .then((data) => {
                setLoading(false)
                setRaceData(data)
            }).catch((error) => {
                setLoading(false)
                error.json().then((err: any) => {
                    setFetchError(err.error)
                })
            })
    }, [])

    const goToSupplyLocation = (id: string) => {
        setTab(3);
        setTimeout(() => {
            setHightlightSupplyLocation(id)
        }, 250)
    }

    return (
        <>
            <Page className='pb-4'>
                { loading &&  <div className="h-full flex justify-center items-center">
                    <Spinner className="!fill-black !w-12 !h-12" />
                </div>}
                {(!loading && !fetchError && raceData) && <>
                    <Header className='bg-blue flex-col !items-start px-6 pt-6 text-white pb-8 !mb-0'>
                        <div>
                            <ArrowLeftIcon className='w-5 h-5' onClick={() => navigate(-1)}/>
                        </div>
                        <div className='flex flex-col items-center w-full flex-1 mt-4 space-y-6'>
                            <h2 className='font-light text-center'>{raceData?.details.title}</h2>
                            <div className='flex items-center justify-around w-full text-xs'>
                                <div className='flex space-x-1'>
                                    <CalendarIcon className='w-4'/>
                                    <Moment format={'DD MMMM'}>{raceData?.details.date}</Moment>
                                </div>
                                <div className='h-3/4 w-[1px] bg-white'/>
                                <div>{raceData?.details.raceLength} KM</div>
                                <div className='h-3/4 w-[1px] bg-white'/>
                                <div className='flex space-x-1'>
                                    <LocationMarkerIcon className='w-4'/>
                                    <span>{raceData?.details.place}</span>
                                </div>
                            </div>
                        </div>
                    </Header>
                    <Tabs className="w-screen bg-white text-xs border-b border-gray-200"
                          value={tab}
                          onChange={(e, t) => {
                              setTab(t);
                              setHightlightSupplyLocation(null)
                          }}
                          variant="scrollable"
                          allowScrollButtonsMobile
                          scrollButtons={true}
                    >
                        <Tab label="Info" className="!px-0 !mr-4 !min-w-0" disableRipple/>
                        <Tab label="Opstelling" disableRipple/>
                        <Tab label="Planning" disableRipple/>
                        <Tab label="Bevoorrading" disableRipple/>
                        <Tab label="Route" disableRipple/>
                    </Tabs>
                    <div className='px-6 pt-4'>
                        <InfoTab tab={tab} raceData={raceData}/>
                        <ParticipantsTab tab={tab} raceData={raceData}/>
                        <PlanningTab tab={tab} raceData={raceData}/>
                        <SupplyTab tab={tab} raceData={raceData} highlightedSupplyLocation={hightlightSupplyLocation}/>
                        <RouteTab tab={tab} raceData={raceData} goToSupplyLocation={goToSupplyLocation}/>
                    </div>
                </>
                }
            </Page>
            <BottomBar />
        </>
    )
}

const InfoTab = ({ tab, raceData }: {tab: number, raceData: RaceObject | null}) => {
    const getWebsiteUrl = (websiteUrl: string): string => {
        if (websiteUrl.includes('http') || websiteUrl.includes('https')) {
            return websiteUrl;
        }

        return 'http://' + websiteUrl;
    }

    return (
        <div className={`${tab === 0 ? 'grid' : 'hidden'} grid-cols-4 gap-3`}>
            <Card className="!shadow-none pt-3 pb-4 px-3.5 col-span-2 space-y-2">
                <h6 className='font-medium'>Plaats</h6>
                <small>{ raceData?.details.place }</small>
            </Card>
            <Card className="!shadow-none pt-3 pb-4 px-3.5 col-span-2 space-y-2">
                <h6 className='font-medium'>Datum</h6>
                <small>{ raceData?.details.date }</small>
            </Card>
            <Card className="!shadow-none pt-3 pb-4 px-3.5 col-span-2 space-y-2">
                <h6 className='font-medium'>Lengte</h6>
                <small>{ raceData?.details.raceLength } KM</small>
            </Card>
            <Card className="!shadow-none pt-3 pb-4 px-3.5 col-span-2 space-y-2">
                <h6 className='font-medium'>Start / finish</h6>
                <small>{ raceData?.details.startTime } - { raceData?.details.finishTime }</small>
            </Card>

            <Card className="!shadow-none pt-3 pb-4 px-3.5 col-span-4 space-y-2">
                <h6 className='font-medium'>Permenance</h6>
                <small>{ raceData?.details.permenance }</small>
            </Card>

            <Card className="!shadow-none pt-3 pb-4 px-3.5 col-span-4 space-y-2">
                <h6 className='font-medium'>Kleedkamers</h6>
                <small>{ raceData?.details.changingRooms }</small>
            </Card>
            <Card className="!shadow-none pt-3 pb-4 px-3.5 col-span-4 space-y-2">
                <h6 className='font-medium'>Extra informatie</h6>
                { raceData?.details.raceWebsite && <a className='link' target="_blank" href={getWebsiteUrl(raceData.details.raceWebsite)}>Bekijk de website</a>}
                <small>{ raceData?.details.extraInfo ?? '-' }</small>
            </Card>
        </div>
    )
}

const ParticipantsTab = ({ tab, raceData }: {tab: number, raceData: RaceObject | null}) => {
    return (
        <div className={`${tab === 1 ? 'grid' : 'hidden'} grid-cols-4 gap-2`}>
            <Card className="!shadow-none pt-3 pb-4 px-3.5 col-span-4 space-y-1">
                <h6 className='font-medium'>Renners</h6>
                <ul>
                    { raceData?.participants.cyclists.map((c, i) => {
                        return (
                            <>
                                <ParticipantListItem key={`pc-${i}`} participant={c} />
                            </>
                        )
                    })}
                </ul>
            </Card>

            <Card className="!shadow-none pt-3 pb-4 px-3.5 col-span-4 space-y-2">
                <h6 className='font-medium'>Reserve renners</h6>
                <ul>
                    { raceData?.participants.reserveCyclists.map((c, i) => {
                        return (
                            <>
                                <ParticipantListItem key={`prc-${i}`} participant={c} />
                            </>
                        )
                    })}
                </ul>
            </Card>

            <Card className="!shadow-none pt-3 pb-4 px-3.5 col-span-4 space-y-2">
                <h6 className='font-medium'>Ploegleiders</h6>
                <ul>
                    { raceData?.participants.teamLeaders.map((c, i) => {
                        return (
                            <>
                                <ParticipantListItem key={`ptl-${i}`} participant={c} />
                            </>
                        )
                    })}
                </ul>
            </Card>

            <Card className="!shadow-none pt-3 pb-4 px-3.5 col-span-4 space-y-2">
                <h6 className='font-medium'>Mechaniekers</h6>
                <ul>
                    { raceData?.participants.mechanics.map((c, i) => {
                        return (
                            <>
                                <ParticipantListItem key={`pm-${i}`} participant={c} />
                            </>
                        )
                    })}
                </ul>
            </Card>

            <Card className="!shadow-none pt-3 pb-4 px-3.5 col-span-4 space-y-2">
                <h6 className='font-medium'>Verzorgers</h6>
                <ul>
                    { raceData?.participants.supporters.map((c, i) => {
                        return (
                            <>
                                <ParticipantListItem key={`ps-${i}`} participant={c} />
                            </>
                        )
                    })}
                </ul>
            </Card>
        </div>
    )
}

const PlanningTab = ({ tab, raceData }: {tab: number, raceData: RaceObject | null}) => {
    return (
        <div className={`${tab === 2 ? 'grid' : 'hidden'} grid-cols-4 gap-2`}>
            <Card className="!shadow-none pt-3 pb-4 px-3.5 col-span-4 space-y-1">
                <h6 className='font-medium'>Planning</h6>
                { raceData?.planningRows.map((pr, i) => <PlanningListItem key={`pr-${i}`} row={pr} />)}
            </Card>
        </div>
    )
}

const SupplyTab = ({ tab, raceData, highlightedSupplyLocation }: {tab: number, raceData: RaceObject | null, highlightedSupplyLocation: string | null}) => {
    return (
        <div className={`${tab === 3 ? 'grid' : 'hidden'} grid-cols-4 gap-2`}>
            <Card className="!shadow-none pt-3 pb-4 px-3.5 col-span-4 space-y-1">
                <h6 className='font-medium'>Bevoorrading locaties</h6>

                <div className='flex flex-col space-y-1'>
                    { raceData?.details.supplyGoogleMapsURL && <a className='link' target="_blank" href={raceData?.details.supplyGoogleMapsURL}>Google maps</a> }
                    { raceData?.details.supplyGoogleMapsURL && <a className='link' download href={raceData?.details.supplyMapFileUrl}>Bevoorradings kaart</a> }
                </div>


                { raceData?.supplyLocations.map((pr, i) => {
                    return <SupplyLocationListItem key={`sl-${i}`} index={i} row={pr} highlighted={pr.id === highlightedSupplyLocation} />
                })}
            </Card>
        </div>
    )
}

const RouteTab = ({ tab, raceData, goToSupplyLocation }: {tab: number, raceData: RaceObject | null, goToSupplyLocation: (n: string) => void}) => {
    return (
        <div className={`${tab === 4 ? 'grid' : 'hidden'} grid-cols-4 gap-2`}>
            <Card className="!shadow-none pt-3 pb-4 px-3.5 col-span-4 space-y-1">
                <h6 className='font-medium'>Route beschrijving</h6>
                { raceData?.routeDirections.map((pr, i) => <RouteListItem key={`rr-${i}`} row={pr} goToSupplyLocation={goToSupplyLocation} />)}
            </Card>
        </div>
    )
}

const ParticipantListItem = ({ participant }: { participant: User }) => {
    return (
        <div className='flex items-center space-x-3 border-b border-black/5 last:border-transparent py-3'>
            <ProfileImage className='w-8 h-8 rounded' src={participant.profileImageUrl} />
            <small className='font-medium flex-1'>{ participant.firstName } { participant.lastName }</small>
        </div>
    )
}

const PlanningListItem = ({ row }: { row: PlanningRow }) => {
    return (
        <div className='border-b border-black/5 last:border-transparent py-3 space-y-2'>
            <small className='font-bold flex-1'>{ row.title }</small>
            <small>{ row.time ?? '-' }</small>
        </div>
    )
}

const SupplyLocationListItem = ({ index, row, highlighted }: { index: number, row: SupplyLocation, highlighted: boolean }) => {
    return (
        <div className={`border-b border-black/5 last:border-transparent py-3 space-y-2`}>
            <div className={`flex justify-between ${highlighted && 'text-sky-500'} transition-colors`}>
                <small className='font-bold flex-1'>{ row.routeRow.street }</small>
                <small className='font-bold'>{ row.routeRow.lengthIndicator } KM</small>
            </div>
            <div className='flex justify-between'>
                <small>{ row.routeRow.junction }</small>
                <small>{ row.routeRow.time ?? '-' }</small>
            </div>
            { (row.extraInfo || row.routeRow.extraInfo) && <small>
                { row.extraInfo } { row.routeRow.extraInfo }
            </small> }
        </div>
    )
}

const RouteListItem = ({ row, goToSupplyLocation }: { row: RouteRow, goToSupplyLocation: (n: string) => void }) => {


    return (
        <>
            { row.place && <div className='!mt-6 flex items-center space-x-3'>
                <span className='font-bold'>{ row.place }</span>
                <div className='flex-1 h-[1px] bg-black' />
                </div>
            }
            <div className='border-b border-black/5 last:border-transparent py-3 '>
                <div className='flex'>
                    <div className='flex-1 space-y-2'>
                        <div className={`flex items-center justify-between ${row.supplyLocation.id && 'underline cursor-pointer'}`} onClick={() => goToSupplyLocation(row.supplyLocation.id)}>
                            <small className='font-bold flex-1'>{ row.street } { row.direction }</small>
                            <small className='font-bold'>{ row.lengthIndicator } KM</small>
                        </div>
                        <div className='flex justify-between'>
                            <small>{ row.junction }</small>
                            <small>{ row.time ?? '-' }</small>
                        </div>
                    </div>
                    <div className='min-w-[2rem] flex justify-end items-center'>
                        { row.supplyLocation.id && <LocationMarkerIcon className='h-5 w-5 rounded-full text-sky-500 cursor-pointer' onClick={() => goToSupplyLocation(row.supplyLocation.id)} />}
                    </div>
                </div>
                { row.extraInfo && <small>
                    { row.extraInfo }
                </small> }
            </div>
        </>
    )
}
