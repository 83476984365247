import {Button} from '../components/Button';
import {TextInput} from '../components/Form/TextInput';
import {FormLabel} from '../components/Form/FormLabel';
import {useAuth} from '../core/contexts/Auth.context';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from "yup";
import React, {useEffect, useRef, useState} from 'react';
import {useNavigate} from 'react-router-dom';

const loginSchema = yup.object({
    email: yup.string().email('Vul een email geldig adres in').required('Vul een email adres in'),
    password: yup.string().required('Vul een wachtwoord in')
})

export const Login = () => {
    const navigate = useNavigate();
    const auth = useAuth();
    const submitButton = useRef(null);
    const [invalidLogin, setInvalidLogin] = useState<string | null>(null)
    const { register, handleSubmit, formState: { errors }} = useForm({
        resolver: yupResolver(loginSchema),
        mode: 'onChange'
    })

    useEffect(() => {
        if (auth.token) {
            navigate('/')
        }
    }, [auth.token])

    useEffect(() => {
        if (auth.loginError) {
            auth.loginError?.json().then((data) => {
                setInvalidLogin(data.error)
            })
        }
    }, [auth.loginError])

    const doLogin = ({email, password}: any) => {
        setInvalidLogin(null);
        auth.login(email, password);
    }

    const onEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            (e.target as HTMLInputElement).blur();
            (submitButton.current as unknown as HTMLButtonElement)?.click()
        }
    }

    return (
        <div className="mx-auto container h-screen p-6 flex justify-center items-center">
            <form onSubmit={handleSubmit(doLogin)} className="w-full space-y-2" noValidate>
                <div>
                    <FormLabel>Email</FormLabel>
                    <TextInput type="email" register={register('email')} inputClassName="w-full" error={errors.email?.message || invalidLogin} />
                </div>

                <div>
                    <FormLabel>Wachtwoord</FormLabel>
                    <TextInput type="password" register={register('password')} inputClassName="w-full" error={errors.password?.message || invalidLogin} onKeyDown={onEnter} />
                </div>

                <div>
                    <Button ref={submitButton} type="submit" className="w-full mt-5" loading={auth.pending}>Login</Button>
                </div>
            </form>
        </div>
    )
}