import {BottomBar} from '../components/BottomBar';
import {Header} from '../components/Header';
import {Page} from '../components/Page';
import {CameraIcon, LogoutIcon} from '@heroicons/react/outline';
import {useAuth} from '../core/contexts/Auth.context';
import {useNavigate} from 'react-router-dom';
import {ProfileImage} from '../components/ProfileImage';
import {TextInput} from '../components/Form/TextInput';
import {FormLabel} from '../components/Form/FormLabel';
import {Button} from '../components/Button';
import * as yup from 'yup';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {get, post} from '../core/Api';
import React, {MutableRefObject, useEffect, useRef, useState} from 'react';
import {Profile as ProfileObject} from '../core/interfaces/Entities';
import {FileInput} from '../components/Form/FileInput';

const profileSchema = yup.object({
    firstName: yup.string().required(),
    lastName: yup.string().required(),
    email: yup.string().email().required(),
    phoneNumber: yup.string().nullable(),

    licenseCode: yup.string().nullable(),
    uciCode: yup.string().nullable(),
    licenseImage: yup.string().nullable()
})

export const Profile = () => {
    const auth = useAuth();
    const navigate = useNavigate();
    const formRef = useRef<HTMLFormElement | null>(null)

    const profileImageInput = useRef<HTMLInputElement | null>(null);
    const licenseImageInput = useRef<HTMLInputElement | null>(null);

    const [profileImageChanged, setProfileImageChanged] = useState<boolean>(false)
    const [licenseImageChanged, setLicenseImageChanged] = useState<boolean>(false)

    const [profileImageUrl, setProfileImageUrl] = useState<string | null>(null)
    const [licenseImageUrl, setLicenseImageUrl] = useState<string | null>(null)

    const [isRealTimeDirty, setIsRealTimeDirty] = useState(false)
    const [defaultValues, setDefaultValues] = useState<ProfileObject | null>(null)
    const [pending, setPending] = useState({form: false, profileImage: false, licenseImage: false})
    const { register, handleSubmit, formState: { errors }, reset} = useForm({
        resolver: yupResolver(profileSchema)
    })

    useEffect(() => {
        get<ProfileObject>('/profile')
            .then((data) => {
                setDefaultValues(data);
                reset(data);
                setProfileImageUrl(data.profileImageUrl)
                setLicenseImageUrl(data.licenseImageUrl)
            })
            .catch(() => {

            })
    }, [])

    const openInput = (ref: MutableRefObject<HTMLInputElement | null>) => {
        if (ref && ref.current) {
            ref.current?.click();
        }
    }

    const doLogout = () => {
        auth.logout();
        navigate('/login');
    }

    const doSaveProfileImage = () => {
        const files = profileImageInput.current?.files;

        if (files) {
            const data = new FormData();
            data.append('file', files[0])

            post<ProfileObject>('/profile/saveProfileImage', data)
                .then((data) => {
                    setProfileImageUrl(data.profileImageUrl)
                    setProfileImageChanged(false)
                    setPending(prevState => ({...prevState, profileImage: false}))
                }).catch(() => {

                })
        }
    }

    const doSaveLicenseImage = () => {
        const files = licenseImageInput.current?.files;

        if (files) {
            const data = new FormData();
            data.append('file', files[0])

            post<ProfileObject>('/profile/saveLicenseImage', data)
                .then((data) => {
                    setLicenseImageUrl(data.licenseImageUrl)
                    setLicenseImageChanged(false)
                    setPending(prevState => ({...prevState, licenseImage: false}))
                }).catch(() => {

            })
        }
    }

    const doSaveProfile = async (values: any) => {
        if (profileImageChanged) {
            setPending(prevState => ({...prevState, profileImage: true}))
            doSaveProfileImage();
        }

        if (licenseImageChanged) {
            setPending(prevState => ({...prevState, licenseImage: true}))
            doSaveLicenseImage();
        }

        setPending(prevState => ({...prevState, form: true}))
        try {
            const data = await post<ProfileObject>('/profile/save', values)
            reset(data)
            setDefaultValues(data)
            setIsRealTimeDirty(false);
            setPending(prevState => ({...prevState, form: false}))
        } catch (err) {
            console.error(err);
        }
    }

    const getLicenseImagePlaceholder = () => {
        if (licenseImageChanged) {
            const files = licenseImageInput.current?.files;

            if (files) {
                return files[0].name;
            } else {
                return 'Kies een afbeelding'
            }
        } else {
            return licenseImageUrl ? 'Kies een andere afbeelding' : 'Kies een afbeelding'
        }
    }

    const resetForm = () => {
        reset(defaultValues as { [x: string]: any; } | undefined)
        setIsRealTimeDirty(false);
    }

    const isPending = (): boolean => {
        return Object.values(pending).some(b => b)
    }

    return (
        <div>
            <Page className="p-6">
                <Header className="justify-between ">
                    <h1 className="font-bold">Profile</h1>
                    <div className="flex items-center space-x-1" onClick={doLogout}>
                        <span className="text-xs">Uitloggen</span><LogoutIcon className="w-6 h-6" />
                    </div>
                </Header>
                <form ref={formRef} id='profile-form' onSubmit={handleSubmit(doSaveProfile)} className='pt-8 space-y-5'>
                    <div className='flex justify-center pb-8'>
                        <div className='relative'>
                            <ProfileImage src={profileImageUrl}/>
                            <div className='absolute -bottom-3 -right-3 w-10 h-10 bg-green-400 rounded-lg flex justify-center items-center'>
                                <CameraIcon className='w-7 h-7 text-white' onClick={() => openInput(profileImageInput)} />
                            </div>
                        </div>
                        <input ref={profileImageInput}
                               className='hidden'
                               type='file'
                               accept='image/jpeg, image/png, image/jpg'
                               onChange={() => setProfileImageChanged(true)}
                        />
                    </div>

                    <div>
                        <h5 className='font-bold mb-2'>Persoonlijke gegevens</h5>
                        <div>
                            <FormLabel>Voornaam</FormLabel>
                            <TextInput register={register('firstName')} inputClassName='w-full' error={errors.firstName?.message} onChange={() => setIsRealTimeDirty(true)}/>
                        </div>

                        <div>
                            <FormLabel>Tussenvoegsel + Achternaam</FormLabel>
                            <TextInput register={register('lastName')} inputClassName='w-full' error={errors.lastname?.message} onChange={() => setIsRealTimeDirty(true)}/>
                        </div>

                        <div>
                            <FormLabel>Email</FormLabel>
                            <TextInput register={register('email')} inputClassName='w-full' error={errors.email?.message} onChange={() => setIsRealTimeDirty(true)}/>
                        </div>

                        <div>
                            <FormLabel>Telefoonnummer</FormLabel>
                            <TextInput register={register('phoneNumber')} inputClassName='w-full' error={errors.phoneNumber?.message} onChange={() => setIsRealTimeDirty(true)}/>
                        </div>
                    </div>

                    <div>
                        <h5 className='font-bold mb-2'>Licentie gegevens</h5>
                        <div>
                            <FormLabel>Licentie code</FormLabel>
                            <TextInput register={register('licenseCode')} inputClassName='w-full' error={errors.licenseCode?.message} onChange={() => setIsRealTimeDirty(true)}/>
                        </div>

                        <div>
                            <FormLabel>UCI code</FormLabel>
                            <TextInput register={register('uciCode')} inputClassName='w-full' error={errors.uciCode?.message} onChange={() => setIsRealTimeDirty(true)}/>
                        </div>

                        <div>
                            <FormLabel>Licentie (document)</FormLabel>
                            <FileInput
                                ref={licenseImageInput}
                                onChange={() => setLicenseImageChanged(true)}
                                accept='image/jpeg, image/png, image/jpg'
                                placeholder={ getLicenseImagePlaceholder() }
                                inputClassName='w-full !mb-2'
                            />
                            { licenseImageUrl && <div className='rounded-lg overflow-hidden'>
                                <img src={licenseImageUrl} key={licenseImageUrl}/>
                            </div> }
                        </div>
                    </div>
                    {/*<Button type='submit' className='w-full'>Opslaan</Button>*/}
                </form>
            </Page>
            <BottomBar showChildren={isRealTimeDirty || licenseImageChanged || profileImageChanged}>
                <div className='h-full w-full flex items-center justify-between'>
                    <Button onClick={resetForm} className='bg-gray-400 px-3'>reset</Button>
                    <Button type='submit' form='profile-form' loading={isPending()}>Opslaan</Button>
                </div>
            </BottomBar>
        </div>

    )
}