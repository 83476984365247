import {ReactNode} from 'react';

class CardProps {
    className?: string
    children?: ReactNode | ReactNode[]
}

export const Card = ({children, className}: CardProps) => {
    return (
        <div className={`${className} bg-white overflow-hidden shadow-lg rounded-2xl`}>{children}</div>
    )
}