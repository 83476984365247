import {Props} from '../core/interfaces/Props';

interface ProfileImageProps extends Props {
    src?: string | null
}

export const ProfileImage = ({ src, className }: ProfileImageProps) => {
    return (
        <div className={`${className} w-32 h-32 rounded-xl overflow-hidden`}>
            { src ?
                <img className='h-full w-full object-cover' src={src}/> :
                <img className='bg-gray-400 h-full w-full object-cover' src='/assets/images/default-profile-picture.png'/>
            }
        </div>
    )
}