import React, {HTMLInputTypeAttribute, InputHTMLAttributes, MutableRefObject, Ref, useRef} from 'react';
import {UseFormRegisterReturn} from 'react-hook-form';
import {ChevronRightIcon} from '@heroicons/react/outline';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
    type?: HTMLInputTypeAttribute
    inputClassName?: string | object
    register?: UseFormRegisterReturn
    error?: string
    onChange?: (e: any) => void,
    multiple?: boolean
    accept?: string
    placeholder: string
}

export const FileInput = React.forwardRef<HTMLInputElement, InputProps>(({type, inputClassName, register, error, onChange, multiple, accept, placeholder, ...props }: InputProps, ref) => {
    const openInput = () => {
        if (ref) {
            (ref as MutableRefObject<HTMLInputElement>).current?.click();
        } else {
            console.error('Ref not set for input field')
        }
    }

    return (
        <div>
            <div className={`
                ${inputClassName ?? ''}
                h-[46px]  py-2.5 px-3 bg-well rounded-xl border border-transparent transition-colors ease-in-out 
                flex justify-between items-center select-none
                ${!error && 'mb-6'}
                ${error && 'border-red-500'}
            `}
                 onClick={openInput}
            >
                <span className='text-black/50'>{ placeholder }</span>
                <ChevronRightIcon className='w-4 h-4' />
            </div>
            <input ref={ref} {...register} className='hidden' type='file' multiple={multiple} accept={accept} onChange={onChange}/>
            {error && <small className="text-red-500 h-6 text-xs">{error}</small>}
        </div>
    )
});