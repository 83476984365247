import React, {Dispatch, ReactNode, SetStateAction, useContext, useEffect, useState} from 'react';
import {post} from '../Api';

export interface AuthContextType {
    token: string | null
    setToken: Dispatch<SetStateAction<string | null>>
    login: (email: string, password: string) => void
    logout:() => void
    pending: boolean
    loginError: Response | null,
}

export const AuthContext = React.createContext<AuthContextType>(null!);

export const useAuth = () => useContext(AuthContext);

export default function AuthProvider({children}: {children: ReactNode}) {
    const [token, setToken] = useState<string | null>(null);
    const [loginError, setLoginError] = useState<Response | null>(null)
    const [pending, setPending] = useState<boolean>(false)

    useEffect(() => {
        if (!token) {
            if (localStorage.getItem('token')) {
                setToken(localStorage.getItem('token'))
            }
        }
    })

    const login = (email: string, password: string): void => {
        setPending(true)
        post<{token: string}>('/login', {email: email, password: password})
            .then(res => {
                setPending(false)
                setToken(res.token)
                localStorage.setItem('token', res.token)
            }).catch((error) => {
                setPending(false)
                setLoginError(error)
            })
    }

    const logout = () => {
        setToken(null)
        localStorage.removeItem('token')
    }

    const value = {token, setToken, login, logout, pending, loginError};

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}